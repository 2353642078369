const Indianregions = ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra & Nagar Haveli, Daman & Diu', 'Lakshadweep', 'Delhi', 'Puducherry', 'Jammu & Kashmir', 'Ladakh']

export default Indianregions;

export const dummyData = {
    Name: "Rahul Singh",
    Area: "H.no 471, Street 76, Saporji Palonji",
    Region: "West Bengal",
    City: "Kolkata",
    Zipcode: "700004",
    Mobile: "+918273728374",
  }